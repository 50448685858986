import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line no-unused-vars
import videoWebIntro from "../../videos/introtraineau.mp4";
import videoWebIntroEte from "../../videos/introweb.mp4";

const IntroVidBottom: React.FC = () => {
  // END-------------------VideoAcc-----------------------------------***
  const refVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    playAfterXSec(0);
    addEventListener("mousemove", () => {
      playAfterXSec(0);
    });
  }, []);

  const playAfterXSec = async (secondsToPause: number) => {
    setTimeout(async () => {
      await refVideo.current?.play();
    }, secondsToPause * 1000);
  };

  // eslint-disable-next-line no-unused-vars
  const hideOnClick = (event: any): void => {
    const divMenu = document.getElementById("viddiv") as HTMLInputElement;
    divMenu.remove();
  };

  const [isSnap, setIsSnap] = useState(false);

  useEffect(() => {
    // Détecte si react-snap est en cours d'exécution
    setIsSnap(navigator.userAgent === "ReactSnap");
  }, []);

  // END-------------------VideoAcc-----------------------------------***

  return (
    <div>
      <div
        id="viddiv"
        onClick={hideOnClick}
        className="z-50 flex flex-col fixed w-full bottom-0 items-end ">
        <div
          onClick={hideOnClick}
          className="relative z-30 top-14 right-2 p-2 cursor-pointer border-[1px] border-white text-2xl rounded-xl w-min bg-white/60 border-robins-egg-blue-800 hover:bg-white/100">
          <p>X</p>
        </div>
        <div className="flex border-[1px] overflow-hidden w-fit  rounded-t-xl opacity-80 z-10">
          {!isSnap && (
            <video
              ref={refVideo}
              controls={true}
              src={videoWebIntroEte}
              loop={true}
              muted={true}
              autoPlay={false}
              width="400"
              height="300"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IntroVidBottom;
