import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesKayak } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { kayakPackages } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
// import IntroVid from "../mainContentComponent/IntroVid";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const Kayak: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(kayakPackages);
  const title = "Kayak";
  const { language } = useContext(AuthContext) as UserContextType;

  // ***-------------------Retrieving Activity-----------------------------------***

  const { setNewLanguage } = useContext(AuthContext) as UserContextType;
  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesKayak();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const jsonLdKayak = {
    "@context": "https://schema.org",
    "@type": "Service",
    name: "Kayak",
    description: "Kayak sur la Rivière-Rouge",
    provider: {
      "@type": "LocalBusiness",
      name: "Aventure Quatre Saisons",
      url: "https://www.aventurequatresaisons.com"
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng" ? "Kayak Rental Rivière-Rouge" : "Location Kayak Rivière-Rouge"
          }
        />
        <meta
          name="description"
          content="Location de kayak sur la Rivière-Rouge, Mont-Tremblant - Laurentides sur la magnifique Rivière-Rouge."
        />

        <title>
          {language === "Eng" ? "Kayak Rental - Rivière-Rouge" : "Location Kayak - Rivière-Rouge"}
        </title>
        <script type="application/ld+json">{JSON.stringify(jsonLdKayak)}</script>
      </Helmet>
      {/* <IntroVid></IntroVid> */}
      <div className="z-20 w-full">
        <MainContentPage
          title={title}
          packagesCanoe={packagesCanoeState}
          hideShowBookingBox={hideShowBookingBox}></MainContentPage>
      </div>
    </div>
  );
};

export default Kayak;
