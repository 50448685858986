import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesCanoe } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { canoePackages } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const EteAccueil: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const { setNewLanguage, language } = useContext(AuthContext) as UserContextType;
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(canoePackages);
  const title = language === "Eng" ? "Canoe" : "Canot";

  // ***-------------------Retrieving Activity-----------------------------------***

  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesCanoe();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const jsonLdCanot = {
    "@context": "https://schema.org",
    "@type": "Service",
    name: "Canot",
    description: "Excursions auto-guidées en Canot sur la magnifique Rivière-Rouge",
    provider: {
      "@type": "LocalBusiness",
      name: "Aventure Quatre Saisons",
      url: "https://www.aventurequatresaisons.com"
    }
  };

  // END---------------------Retrieving Activity-----------------------------------***
  return (
    <div className="z-20 w-full">
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng"
              ? "Canoeing Rivière-Rouge & Mont-Tremblant - Scenic Adventures"
              : "Canot Rivière-Rouge & Mont-Tremblant - Excursions Nature"
          }
        />

        <meta
          name="description"
          content={
            language === "Eng"
              ? "Explore our canoeing trips on Rivière-Rouge in Mont-Tremblant. Paddle through the stunning landscapes of the Laurentians."
              : "Découvrez nos excursions en canot sur la Rivière-Rouge à Mont-Tremblant. Pagayez à travers les paysages magnifiques des Laurentides."
          }
        />

        <title>
          {language === "Eng"
            ? "Canoeing Rivière-Rouge & Mont-Tremblant - Scenic Adventures"
            : "Canot Rivière-Rouge & Mont-Tremblant - Excursions Nature"}
        </title>

        <script type="application/ld+json">{JSON.stringify(jsonLdCanot)}</script>
      </Helmet>

      <MainContentPage
        title={title}
        packagesCanoe={packagesCanoeState}
        hideShowBookingBox={hideShowBookingBox}></MainContentPage>
    </div>
  );
};

export default EteAccueil;
